html {
  background-color: #CBA0F1;
}

.App {
  text-align: center;
  font-family: helvetica;
  font-size: 1.1em;
}

h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 3em;
  font-weight: bold;
}

.navbar-container {
  background-image: linear-gradient(#DDD7FF, #CBA0F1);
}
