.card-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 5%;
}

.project-card {
  border: 4px solid black;
  border-radius: 25px;
  background-image: linear-gradient(#ddd7ff, #701dd6);
  box-shadow: 6px 6px rgba(0,0,0,.4);
  height: fit-content;
  width: 400px;
  margin: 1%;
}

.link-btns {
  display: flex;
  border-bottom: solid gray 1px;
}

.link-btns a {
  width: 100%;
  color: #701DD6;
  text-decoration: none;
  background-image: linear-gradient(to right, #CBA0F1, #ddd7ff, #CBA0F1);
  transition: opacity .75s;
  font-size: 1.5em;
  padding: 2% 0;
}

.link-btns a:hover {
  opacity: 0.5;
}

.project-img {
  width: inherit;
}

.description {
  text-align: left;
  padding: 0 5%;
  margin: 5%;
  background-color: white;
  border: 1px solid black;
  opacity: .7;
}
