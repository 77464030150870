header {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.nav-bar {
  display: flex;
  justify-content: space-evenly;
  background-image: linear-gradient(to right, #CBA0F1, #ddd7ff, #ddd7ff, #ddd7ff, #CBA0F1);
  width: 100%;
  border-bottom: solid gray 1px;
  margin-bottom: 1%;
  /* Implement this once header card is separated from navbar
  position: sticky;
  top: 0;
  z-index: 99; */
}

.nav-bar a, .title-card a {
  color: #701DD6;
  text-decoration: none;
  transition: opacity .75s;
  font-size: 1.5em;
  padding: 1% 0;
}

.nav-bar a:hover, .title-card a:hover {
  opacity: 0.3;
}

.title-card {
  border: 4px solid black;
  border-radius: 25px;
  background-image: linear-gradient(#ddd7ff, #701dd6);
  box-shadow: 6px 6px rgba(0,0,0,.4);
  height: fit-content;
  width: 700px;
  margin-bottom: 1%;
}

.title-card h1 {
  margin: 28px 0 -4px;
}

.artifacts {
  border-bottom: solid gray 1px;
}

@media(max-width: 700px) {
  .title-card {
    width: 500px;
  }
}

@media(max-width: 500px) {
  .title-card {
    width: 400px;
  }
}

@media(max-width: 400px) {
  .title-card {
    width: 350px;
  }
}
